<template>
	<ratio-frame class="el-carousel-custom" square>
		<el-carousel :height="height" :initial-index="initialIndex" :trigger="trigger" :autoplay="autoplay" :interval="interval" :indicator-position="indicatorPosition" :arrow="arrow" :type="type" :loop="loop" :direction="direction" @change="handleChange">
			<el-carousel-item v-for="(item, i) in list" :key="i">
				<slot :scope="item" />
			</el-carousel-item>
		</el-carousel>
	</ratio-frame>
</template>

<script>
export default {
	components: {
		RatioFrame: () => import("@/components/ratio-frame"),
	},
	props: {
		list: {
			type: Array,
			required: true
		},
		height: String,
		initialIndex: Number,
		trigger: String,
		autoplay: Boolean,
		interval: Number,
		indicatorPosition: String,
		arrow: String,
		type: String,
		loop: Boolean,
		direction: String,
	},
	methods: {
		handleChange(arg) {
			this.$emit("change", arg);
		}
	}
}
</script>

<style lang="scss">
.el-carousel-custom {
	.ratio-frame {
		width: 100%;
	}
	.el-carousel {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.el-carousel__container {
		height: auto;
		flex: 1;
	}
}
</style>